import { MEDIA_URL, SITE_TITLE } from '@/constants/env';
import '@/styles/globals.css';
import '@splidejs/splide/dist/css/splide.min.css';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';

export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>{SITE_TITLE}</title>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
        <link href={MEDIA_URL.concat('/favicon.png')} rel="shortcut icon" type="image/png" />
      </Head>
      <Component {...pageProps} />
      <Toaster position="bottom-left" reverseOrder={true} />
    </>
  );
}
